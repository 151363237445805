import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
    walletlink: {
        package: CoinbaseWalletSDK,
        options: {
            appName: "Quantum",
            rpc: {
                56: "https://bsc-dataseed.binance.org/",
                97: "https://endpoints.omniatech.io/v1/bsc/testnet/public"
            }
        }
    },
    walletconnect: {
        package: WalletConnect,
        options: {
            rpc: {
                56: "https://bsc-dataseed.binance.org/",
                97: "https://endpoints.omniatech.io/v1/bsc/testnet/public"
            }
        },
        network: 'binance'
    },

    binancechainwallet: {
        package: true,
        options: {
            rpc: {
                56: "https://bsc-dataseed.binance.org/",
                97: "https://endpoints.omniatech.io/v1/bsc/testnet/public"
            }
        },
    },
};
