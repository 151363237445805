import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { useSelector } from "react-redux";
import RecentTransactionnModal from "./RecentTransactionModal.js";
import { unstakeUniqueUserLockAction, updateAirdrop, updateapy, updatelockperiod, updateTokenPrice, updateUnstakeUsersAction } from "../helper/calculation.js";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import QuantumIcoabi from '../ABI/QuantumIcoabi'
import stakingabi from '../ABI/Stakingabi.json'
import config from '../config/config'
import { connection } from "../helper/connection.js";
// Scroll to Top
function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return null;
}

var initialValues = {
    "apypercent": '',
    "lockperiod": '',
    "tokenprice": '',
    "unstakeusers": '',
    "unstakeLocktime": '',
    "airdropaddress": '',
    "airdropamount": '',
    "unstakeLockaddresss": ''
}
var errorinitial = {
    "apyper": "",
    "lockperioderr": "",
    "tokenpriceerr": "",
    "unstakeuser": "",
    "airdropaddres": "",
    "airdropammt": "",
    "unstakeLocktimeErr": '',
    "unstakeLockaddressErr": ''

}

export default function Admin(props) {
    const [loadapy, setLoadapy] = useState(false)
    const [loadper, setLoadper] = useState(false)
    const [loadprice, setLoadprice] = useState(false)
    const [stakeLoad, setstakeLoad] = useState(false)
    const [airdropload, setairdropload] = useState(false)
    const [unstakelockload, setunstakelockload] = useState(false)
    const [unstakestatus, setunstakestatus] = useState(false)
    const [transactionHashh, settransactionHash] = useState('')
    const [Error, setError] = useState(errorinitial)
    const { apyper, lockperioderr, tokenpriceerr, unstakeuser, airdropaddres,
        airdropammt, unstakeLocktimeErr, unstakeLockaddressErr } = Error
    const [values, setValues] = useState(initialValues)
    const { apypercent, lockperiod, tokenprice, unstakeusers,
        airdropaddress, airdropamount, unstakeLocktime, unstakeLockaddresss } = values
    const walletConnection = useSelector((state) => state.walletConnection);

    useEffect(() => {
        getDetailsOfContract()
    }, [walletConnection])

    async function getDetailsOfContract() {
        try {
            var get = await connection()
            if (get && get.web3) {
                var address = get.address;
                var web3 = get.web3;

                var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
                var Contract = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
                var apyercent = await contract.methods.apyPercentage().call()
                apyercent = parseFloat(apyercent) / (10 ** 2)
                var locktime = await contract.methods.lockingPeriod().call()
                locktime = locktime / 86400;
                var tokenbusd = await Contract.methods.getTokenprice().call();
                let last_0 = 1000000 / parseFloat(tokenbusd)
                // console.log('eeeeeeeeeeeeeeeeeeeee: ', last_0);
                setValues({ ...values, ...{ 'apypercent': apyercent, 'lockperiod': locktime, 'tokenprice': last_0 } })
            }

        } catch (err) {
            console.log('err: ', err);

        }
    }
    const handlechange = async (e) => {
        const { name, value } = e.target
        if (value && ((name == 'airdropamount') || (name == 'unstakeLocktime'))) {
            var regex = /^[0-9.,]+$/;
            if (!regex.test(value)) {
                return
            }
        }
        if (value && ((name == 'lockperiod') || (name == 'unstakeLocktime'))) {
            var checkDeci = value.split('.')
            if (checkDeci.length === 2) {
                return
            }
        }
        setValues({ ...values, ...{ [name]: value } })
        return setError(errorinitial)
        // return setError({ ...Error, ...{ "apyper": '', "lockperioderr": '', "tokenpriceerr": '', "unstakeuser": '', "airdropaddres": '', "airdropammt": '' } })
    }
    const unstakeusersstatus = async (status) => {
        if (status) {
            setunstakestatus(status)
        }
        else {
            setunstakestatus(false)
        }
    }

    const submitfunction = async (e, name) => {
        e.preventDefault()

        if (!isEmpty(name)) {
            if (name == 'apy') {
                setLoadapy(true)
                if (isEmpty(apypercent) || parseFloat(apypercent) <= 0) {
                    let error = 'Please fill valid apy percentage'
                    setLoadapy(false)
                    return setError({ ...Error, ...{ "apyper": error, "lockperioderr": '', "tokenpriceerr": '', "unstakeuser": '', "airdropaddres": '', "airdropammt": '' } })
                }
                const { status, message, transactionHash } = await updateapy(apypercent)
                if (status == true) {
                    setLoadapy(false)
                    getDetailsOfContract()
                    settransactionHash(transactionHash)
                    toastAlert('success', message)
                    window.$('#recent_trans_modal').modal('show');
                    // setapypercent("")
                    setValues(initialValues)
                }
                else {
                    setLoadapy(false)
                    toastAlert('error', message)
                }
            }
            else if (name == 'lock') {
                setLoadper(true)
                if (isEmpty(lockperiod) || parseFloat(lockperiod) <= 0) {
                    let error = 'Please fill valid lock period'
                    setLoadper(false)
                    return setError({ ...Error, ...{ "apyper": '', "lockperioderr": error, "tokenpriceerr": '', "unstakeuser": '', "airdropaddres": '', "airdropammt": '' } })
                }
                const { status, message, transactionHash } = await updatelockperiod(lockperiod)
                if (status == true) {
                    setLoadper(false)
                    getDetailsOfContract()
                    settransactionHash(transactionHash)
                    // setlockperiod("")
                    setValues(initialValues)
                    toastAlert('success', message)
                    window.$('#recent_trans_modal').modal('show');
                }
                else {
                    setLoadper(false)
                    // setlockperiod()
                    setValues(initialValues)
                    toastAlert('error', message)
                }
            }
            else if (name == 'tokenprice') {
                setLoadprice(true)
                if (isEmpty(tokenprice) || parseFloat(tokenprice) <= 0) {
                    let error = 'Please fill valid token price'
                    setLoadprice(false)
                    return setError({ ...Error, ...{ "apyper": '', "lockperioderr": '', "tokenpriceerr": error, "unstakeuser": '', "airdropaddres": '', "airdropammt": '' } })
                }
                const { status, message, transactionHash } = await updateTokenPrice(tokenprice)
                if (status == true) {
                    setLoadprice(false)
                    getDetailsOfContract()
                    settransactionHash(transactionHash)
                    // settokenprice("")
                    setValues(initialValues)
                    window.$('#recent_trans_modal').modal('show');
                    toastAlert('success', message)
                }
                else {
                    setLoadprice(false)
                    // settokenprice()
                    setValues(initialValues)
                    toastAlert('error', message)
                }
            }
        }
        else {
            toastAlert('error', 'Rejected')
            setValues(initialValues)
        }
    }

    const UpdateUnstakeUsers = async (e) => {
        e.preventDefault();
        if (isEmpty(unstakeusers)) {
            let error = "Please fill address"
            return setError({ ...Error, ...{ "apyper": '', "lockperioderr": '', "tokenpriceerr": '', "unstakeuser": error, "airdropaddres": '', "airdropammt": '' } })
        }
        else {
            setstakeLoad(true)
            const { status, message, transactionHash } = await updateUnstakeUsersAction(unstakeusers, unstakestatus)

            if (status == true) {
                setstakeLoad(false)
                settransactionHash(transactionHash)
                // setunstakeusers("")/////
                setValues(initialValues)
                toastAlert('success', message)
                window.$('#recent_trans_modal').modal('show');
            }
            else if (status == false) {
                setstakeLoad(false)
                toastAlert('error', message)
            }
            else {
                setstakeLoad(false)
                toastAlert('error', message)
            }
        }
    }
    const unstakeUniqueUserLock = async () => {
        let errors = {};
        if (isEmpty(unstakeLockaddresss)) {
            errors.unstakeLockaddressErr = 'Please fill unstake address'
        }
        if (isEmpty(unstakeLocktime)) {
            errors.unstakeLocktimeErr = "Please fill lock time"
        }
        if (parseFloat(unstakeLocktime) <= 0) {
            errors.unstakeLocktimeErr = 'Please fill valid lock time'
        }
        if (!isEmpty(errors)) {
            return setError({ ...Error, ...{ "unstakeLockaddressErr": errors.unstakeLockaddressErr, "unstakeLocktimeErr": errors.unstakeLocktimeErr } })
        }
        setunstakelockload(true)
        const { status, message, transactionHash } = await unstakeUniqueUserLockAction(unstakeLockaddresss, unstakeLocktime)
        if (status == true) {
            setunstakelockload(false)
            settransactionHash(transactionHash)
            setValues(initialValues)
            toastAlert('success', message)
            window.$('#recent_trans_modal').modal('show');
        }
        else if (status == false) {
            setunstakelockload(false)
            toastAlert('error', message)
        }
    }

    const addairdrop = async (e) => {
        e.preventDefault()
        let errors = {}
        if (isEmpty(airdropaddress)) {
            errors.airdropaddres = 'Please fill airdrop address'
        }
        if (isEmpty(airdropamount)) {
            errors.airdropammt = 'Please fill airdrop amount'
        }
        if (parseFloat(airdropamount) <= 0) {
            errors.airdropammt = 'Please fill valid airdrop amount'
        }
        if (!isEmpty(errors)) {
            return setError({ ...Error, ...{ "apyper": '', "lockperioderr": '', "tokenpriceerr": '', "unstakeuser": '', "airdropaddres": errors.airdropaddres, "airdropammt": errors.airdropammt } })
        }
        setairdropload(true)
        const { status, message, transactionHash } = await updateAirdrop(airdropaddress, airdropamount)
        if (status == true) {
            setairdropload(false)
            settransactionHash(transactionHash)
            toastAlert('success', message)
            setValues(initialValues)
        }
        else if (status == false) {
            setairdropload(false)
            toastAlert('error', message)
        }
    }
    return (
        <div>
            <ScrollToTopOnMount />
            <Navbar
            />

            <div className="buytoken">
                <div className="buytokenbg">
                    <div className="container">
                        <div className="head">
                            <h2 className="main_title">Buy QUANTUM REAR RESISTANCE Presale</h2>
                            <p>
                                Participate in  QUANTUM REAR RESISTANCE staking, earn  QUANTUM REAR RESISTANCE while you
                                sleep
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="box">

                                    <div className="box_ctn">
                                        <p className="receive" >APY:

                                            <input
                                                type="number"
                                                class="admininput form-control"
                                                name="apypercent"
                                                onChange={handlechange}
                                                value={apypercent}
                                                placeholder="Enter APY percentage"
                                            />
                                        </p>
                                        {
                                            !isEmpty(apyper) ? <span className="errortext">{apyper}</span> : ""
                                        }
                                        <div>
                                            {
                                                loadapy == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' onClick={event => submitfunction(event, 'apy')}>Update</button>

                                            }
                                        </div>


                                        <p className="receive" >Lock Period:
                                            {/* <span> 0 </span> */}
                                            <input
                                                type="number"
                                                class="admininput form-control"
                                                name="lockperiod"
                                                onChange={handlechange}
                                                value={lockperiod}
                                                placeholder="Enter lock period (In days)"
                                            />
                                        </p>
                                        {
                                            !isEmpty(lockperioderr) ? <span className="errortext">{lockperioderr}</span> : ""
                                        }
                                        <div>
                                            {
                                                loadper == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' onClick={event => submitfunction(event, 'lock')}>Update</button>

                                            }
                                        </div>


                                        <p className="receive">Token Price:

                                            <input
                                                type="number"
                                                class="admininput form-control"
                                                name="tokenprice"
                                                onChange={handlechange}
                                                value={tokenprice}
                                                placeholder="Enter token price"
                                            />
                                        </p>
                                        {
                                            !isEmpty(tokenpriceerr) ? <span className="errortext">{tokenpriceerr}</span> : ""
                                        }
                                        <div>
                                            {
                                                loadprice == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' onClick={event => submitfunction(event, 'tokenprice')}>Update</button>

                                            }
                                        </div>
                                        <p className="receive">Unstake Users:

                                            <input
                                                type="text"
                                                class="admininput form-control"
                                                name="unstakeusers"
                                                onChange={handlechange}
                                                value={unstakeusers}
                                                placeholder="0xC063C...12 , 0xe9270...cC"
                                            />
                                        </p>

                                        {
                                            !isEmpty(unstakeuser) ? <span className="errortext">{unstakeuser}</span> : ""
                                        }
                                        <div className="d-flex">

                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => unstakeusersstatus(false)} defaultChecked />
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                    False
                                                </label>

                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => unstakeusersstatus(true)} />
                                                <label class="form-check-label" for="flexRadioDefault2">
                                                    True
                                                </label>
                                            </div>

                                        </div>
                                        <div>
                                            {
                                                stakeLoad == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px', marginTop: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px', marginTop: '10px' }} type="button" className='primary_btn' onClick={UpdateUnstakeUsers}>Update</button>
                                            }
                                        </div>

                                        <p className="receive">Unstake Users (Lock Period):

                                            <input
                                                type="text"
                                                class="admininput form-control"
                                                name="unstakeLockaddresss"
                                                onChange={handlechange}
                                                value={unstakeLockaddresss}
                                                placeholder="0xC063C...12 , 0xe9270...cC"
                                            />
                                        </p>

                                        {
                                            !isEmpty(unstakeLockaddressErr) ? <span className="errortext">{unstakeLockaddressErr}</span> : ""
                                        }

                                        <p className="receive" >
                                            {/* <span> 0 </span> */}
                                            <input
                                                type="text"
                                                class="admininput form-control"
                                                name="unstakeLocktime"
                                                onChange={handlechange}
                                                value={unstakeLocktime}
                                                placeholder="1 , 2 "
                                            />
                                        </p>

                                        {
                                            !isEmpty(unstakeLocktimeErr) ? <span className="errortext">{unstakeLocktimeErr}</span> : ""
                                        }
                                        <div>
                                            {
                                                unstakelockload == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' onClick={event => unstakeUniqueUserLock(event, 'unstakeuniquelock')}>Update</button>

                                            }
                                        </div>

                                        <p className="receive">Add airdrop:

                                            <div>
                                                <input
                                                    type="text"
                                                    class="admininput form-control"
                                                    name="airdropaddress"
                                                    onChange={handlechange}
                                                    value={airdropaddress}
                                                    placeholder="0xC063C...12 , 0xe9270...cC"
                                                />
                                                {
                                                    !isEmpty(airdropaddres) ? <span style={{ color: 'red' }} className="errortext">{airdropaddres}</span> : ""
                                                }
                                                <input
                                                    type="text"
                                                    class="admininput form-control"
                                                    name="airdropamount"
                                                    onChange={handlechange}
                                                    value={airdropamount}
                                                    placeholder="0.001 , 0.002"
                                                />
                                                {
                                                    !isEmpty(airdropammt) ? <span style={{ color: 'red' }} className="errortext">{airdropammt}</span> : ""
                                                }
                                            </div>
                                        </p>

                                        <div>
                                            {
                                                airdropload == true ?
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' disabled>Processing...</button>
                                                    :
                                                    <button style={{ width: '150px', marginBottom: '10px' }} type="button" className='primary_btn' onClick={event => addairdrop(event, 'airdrop')}>Update</button>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <WalletModal /> */}
            <RecentTransactionnModal
                transactionHash={transactionHashh}
            />
            <Footer />
        </div>
    );
}
