import Web3 from "web3";
import config from "../config/config";
import store from '../store';
import BUSDabi from '../ABI/BUSDabi.json'
import { divideDecimal } from "./customMath";
import { convert } from "./convert";

export async function connectionNew() {

    var currentProvider = store.getState()

    var connect = {
        web3: "",
        address: "",
        network: 0,
        provider: "",
        isChange: "false"
    };

    var provider = (currentProvider && currentProvider.walletConnection &&
        currentProvider.walletConnection.provider
        && currentProvider.walletConnection.provider !== "") ? currentProvider.walletConnection.provider : "";

    var isConnected = "no"
    var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("wagmi.wallet")
    if (WEB3_CONNECT_CACHED_PROVIDER) {
        // console.log('WEB3_CONNECT_CACHED_PROVIDER: ', WEB3_CONNECT_CACHED_PROVIDER);
        var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
        if (connnector === "metaMask" || connnector === "walletConnect") {
            isConnected = "yes";
        }
    }

    if (isConnected && provider && provider !== "" && currentProvider && currentProvider.walletConnection &&
        currentProvider.walletConnection.provider) {
        provider = currentProvider.walletConnection.provider
        console.log('provideddddddddddddddddddddr: ', provider);

        //var provider = await web3Modal.connect();
        var web3 = new Web3(provider);
        if (typeof web3 !== "undefined") {

            var network = await web3.eth.net.getId();
            var result = await web3.eth.getAccounts();

            var currAddr = result[0];
            console.log('currAdsssssssssssssdr: ', currAddr);
            var bnbBalance = await web3.eth.getBalance(currAddr);
            bnbBalance = bnbBalance / 10 ** 18
            if (network === config.NetworkId) {
                var Contract = new web3.eth.Contract(BUSDabi, config.BUSDAddress);
                var busdBalance = await Contract.methods.balanceOf(currAddr).call();
                var decimals = await Contract.methods.decimals().call();
                var busdBal = await divideDecimal(busdBalance, decimals);
                busdBal = await convert(busdBal);
            }

            if (currAddr === undefined) currAddr = "";
            if (network === config.NetworkId) {
                connect.network = network;
                connect.web3 = web3;
                connect.address = currAddr;
                connect.provider = provider;
                connect.isChange = "false";
                connect.connect = "yes";
                connect.bnbbal = bnbBalance;
                connect.busdBal = busdBal;
            }
            else if (network !== config.NetworkId) {
                connect.web3 = "";
                connect.address = "";
                connect.network = 0;
                connect.provider = "";
                connect.isChange = "true";
            }
            else {
                return connect;
            }

        }
    }
    return connect;
}