import React from "react";

import { BrowserRouter as Router, Routes, Route, useRoutes, BrowserRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Home from './pages/home.js';
import Buy_Token from './pages/buytoken';
import Staking from "./pages/staking.js";
import Admin from "./pages/Admin.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Provider } from "react-redux";
import store from './store'
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { bsc, bscTestnet } from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'



const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [publicProvider()],
)
console.log({ chains }, 'chainschainschainswwwww')

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({
      chains,
      shimDisconnect: true,
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "buytoken", element: <Buy_Token /> },
    { path: "Staking", element: <Staking /> },
    { path: "quantzwerytuhgfsaxbmjerhewr", element: <Admin /> }
  ]);
  return routes;
};

AOS.init();

const AppWrapper = () => {
  return (
    <Provider store={store}>
      {/* <BrowserRouter basename="/ui-templates/quantumrearresistance/"> */}
      <BrowserRouter basename="/">
        <WagmiConfig config={config}>
          <ToastContainer />
          <App />
        </WagmiConfig>
      </BrowserRouter>
    </Provider>
  );
};

export default AppWrapper;