// import Web3 from "web3";
import BUSDabi from '../ABI/BUSDabi.json'
import Quantumtokenabi from '../ABI/Quantumtokenabi.json'
import { divideDecimal } from "../helper/customMath";
import config from '../config/config'
import { convert } from "../helper/convert";
import { connection } from "../helper/connection";

export async function getbalance(from, symbol) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var balanceOf = 0;
            var bal = 0;
            if (symbol === config.BNBSYMBOL) {
                var getBalance = await web3.eth.getBalance(address);
                balanceOf = getBalance;
                bal = balanceOf / 10 ** 18
            } else {
                var Contract = new web3.eth.Contract(BUSDabi, from);////
                balanceOf = await Contract.methods.balanceOf(address).call();
                var decimals = await Contract.methods.decimals().call();
                bal = await divideDecimal(balanceOf, decimals);
                bal = await convert(bal);
            }
            return {
                balance: bal,
                balanceOf: balanceOf,
                error: ""
            };
        } else {
            return {
                balance: 0,
                balanceOf: 0,
                error: ""
            };
        }
    } catch (err) {
        return {
            balance: 0,
            balanceOf: 0,
            error: err
        };
    }
}

export async function allowance(contractAddr, spender) {//busdadd,quantumico
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var owner = get.address;
            var Contract = new web3.eth.Contract(BUSDabi, contractAddr);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
//allowance-QRR
export async function allowanceQRR(spender) {
    //spender - QRRicocontract
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var owner = get.address;
            var Contract = new web3.eth.Contract(Quantumtokenabi, config.QuantumToken);
            var result1 = await Contract.methods.allowance(
                owner,
                spender
            ).call();
            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
export async function approve(contractAddr) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(BUSDabi, contractAddr);
            var approveAmt = 1000000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);
            var result1 = await Contract.methods.approve(
                config.QUANTUMICO,
                approveAmt.toString()
            ).send({ from: address });

            return {
                value: result1,
                status: true
            };
        } else {
            return {
                value: {},
                status: false
            };
        }
    } catch (err) {
        return {
            value: {},
            status: false
        };
    }
}
//approveQRRtoken
export async function approveQRRtoken(contractAddr,stakeval) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            var Contract = new web3.eth.Contract(Quantumtokenabi, contractAddr);
            var approveAmt = 1000000000 * (10 ** 18);
            approveAmt = await convert(approveAmt);

            var bal = await Contract.methods.balanceOf(address).call();
            bal = parseFloat(bal) / (10 ** 18);
            if(parseFloat(bal) < parseFloat(stakeval)){
                return {
                    value: {},
                    approvereturnval: 0,
                    status: false,
                    message:"Insufficient balance"
                };
            }
            var result1 = await Contract.methods.approve(
                config.StakingContract,
                approveAmt.toString()
            ).send({ from: address });
            var approvereturnval = result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ?
                result1.events.Approval.returnValues.value : 0
            approvereturnval = parseFloat(approvereturnval) / 10 ** 18
            return {
                value: result1,
                approvereturnval: approvereturnval,
                status: true,
                message:'Approved success'

            };
        } else {
            return {
                value: {},
                approvereturnval: 0,
                status: false,
                message:"Approved Failed"

            };
        }
    } catch (err) {
        return {
            value: {},
            approvereturnval: 0,
            status: false,
            message:"Approved Failed"
        };
    }
}

export async function getQRRBalance() {
    var get = await connection()
    try {
        if (get && get.web3 && get.address) {
            var address = get.address;
            var web3 = get.web3;
            var Contract = new web3.eth.Contract(Quantumtokenabi, config.QuantumToken);
            var balanceOf = await Contract.methods.balanceOf(address).call();
            var decimals = await Contract.methods.decimals().call();
            var bal = await divideDecimal(balanceOf, decimals);
            bal = await convert(bal);
            return {
                balance: bal,
                balanceOf: balanceOf,
                error: ""
            };
        }
    }
    catch (err) {
        return {
            balance: 0,
            balanceOf: 0,
            error: ""
        };
    }
}

