import React, { useEffect, useState } from 'react'
import { approveQRRtoken, getQRRBalance } from '../action/contractActions'
import { getAllowanceDetails, Getstakeamount, StakeTimerFunction, staking, unstaking } from '../helper/calculation'
import { convert } from '../helper/convert'
import config from '../config/config'
import { toastAlert } from '../helper/toastAlert'
import isEmpty from 'is-empty'
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import { useSelector } from 'react-redux'
import RecentTransactionnModal from './RecentTransactionModal'

const StakeModal = (props) => {
    const [stakeval, setstakeval] = useState()
    const [error, setError] = useState('')
    const [load, setload] = useState(false)
    const [unstakeTimer, setUnstakeTimer] = useState()
    const [stakebalnce, setStakebalance] = useState(0)
    const [unstakebalnce, setunstakeBal] = useState(0)
    const [showTimer, setShowTimer] = useState(false)
    const [UnstakeUserStatus, setUnstakeUserStatus] = useState()
    // const [approveBalances, setApproveBalances] = useState(0)
    const [transactionHashh, settransactionHash] = useState('')
    // const [CurrDate, setCurrDate] = useState()
    const walletConnection = useSelector((state) => state.walletConnection);

    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <div className="timer_panel">
                <span className="num num1">
                    <span className="timer_time">{zeroPad(days)}</span>
                    <span className="timer_label">Days</span>
                </span>

                <span className="num num2">
                    <span className="timer_time">{zeroPad(hours)}</span>
                    <span className="timer_label">Hours</span>
                </span>

                <span className="num num3">
                    <span className="timer_time">{zeroPad(minutes)}</span>
                    <span className="timer_label">Minutes</span>
                </span>

                <span className="num num4">
                    <span className="timer_time">{zeroPad(seconds)}</span>
                    <span className="timer_label">Seconds</span>
                </span>
            </div>
        );
    };
    useEffect(() => {
        getunstakeTime()
    }, [walletConnection,props.stakebalnce])

    const getunstakeTime = async () => {
        await StakeTimerFunction(setUnstakeTimer, setShowTimer)
        // await StakeTimerFunction(setUnstakeTimer)
        var data = await getQRRBalance()
        var dataTwo = await Getstakeamount()
        setUnstakeUserStatus(dataTwo && dataTwo.unstakeUserStatus)
        let databal = data && parseFloat(data.balance)
        let qrrbal = props.stakebalnce ? parseFloat(props.stakebalnce) : parseFloat(databal)
        setStakebalance(qrrbal)
        setunstakeBal(dataTwo && dataTwo.amount)
    }
    const setMaxVal = (val) => {
        setError('')
        setstakeval(val)
    }

    const handlechange = async (e) => {
        const { id, value } = e.target
        if (value && parseFloat(value) >= 0) {
            setError('')
            var value_s = value.toString()
            value_s = await convert(value_s)
            var resultone = parseFloat(value_s);
            setstakeval(resultone)
        } else {
            setstakeval()
        }
    }

    const stakeTokensubmit = async (e) => {
        e.preventDefault()
        setload(true)
        try {
            if (parseFloat(stakeval) <= 0 || isEmpty(stakeval)) {
                let error = "Please enter valid amount"
                setload(false)
                return setError(error)
            }
            let values = await getAllowanceDetails()
            // var appBalan = 0;
            if (parseFloat(stakeval) > parseFloat(values)) {
                try {
                    setload(true)
                    let { value, approvereturnval, message } = await approveQRRtoken(config.QuantumToken, stakeval)
                    // appBalan = parseFloat(approvereturnval)
                    // setApproveBalances(appBalan)
                    // if(appBalan > stakeval){
                    // }
                    if (value && value.status == true) {
                        setload(false)
                        toastAlert('success', message)
                    }
                    else {
                        setload(false)
                        toastAlert("error", message);
                    }

                }
                catch (err) {
                }
            }
            else {
                setload(true)
                let { status, message, transactionHash } = await staking(stakeval, stakebalnce)
                if (status == false) {
                    setload(false)
                    // setShowTimer(false)
                    settransactionHash('')
                    toastAlert('error', message)
                }
                else if (status == true) {
                    setload(false)
                    setShowTimer(true)
                    setstakeval('')
                    props.getstakeamount()
                    getunstakeTime()
                    settransactionHash(transactionHash)
                    window.$("#stakemodal").modal("hide");
                    window.$('#recent_trans_modal').modal('show');
                    toastAlert('success', message)
                }
            }
        }
        catch (err) {
            setload(false)
        }
    }
    const unstakeTokensubmit = async (e) => {
        e.preventDefault()
        try {
            setload(true)
            let { status, message, unstakeTime, transactionHash } = await unstaking(UnstakeUserStatus)
            if (status == false) {
                setload(false)
                settransactionHash('')
                toastAlert('error', message)
            }
            else if (status == true) {
                setload(false)
                props.getstakeamount()
                getunstakeTime()
                toastAlert('success', message)
                settransactionHash(transactionHash)
                window.$("#stakemodal1").modal("hide");
                window.$('#recent_trans_modal').modal('show');
            }
        }
        catch (err) {
        }
    }

    const hideModal1 = async () => {
        window.$("#stakemodal1").modal("hide");
        setstakeval('')
    }

    const hideModal = async () => {
        window.$("#stakemodal").modal("hide");
        setError("")
        setstakeval('')
    }

    const getStopTime = () => {
        setShowTimer(false)
    }

    return (

        <div>
            {/* Stake modal */}
            <div
                className="modal fade primary_modal stakemodal"
                id="stakemodal"
                data-bs-backdrop="static"
                tabIndex={-1}
                aria-labelledby="stakemodal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="stakemodal">
                                Stake Tokens
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={hideModal}
                            ></button>
                        </div>
                        <div className="modal-body buytoken mt-0">
                            <div className="label">
                                <p className="text-end" >Balance:   <span>{parseFloat(stakebalnce)} QRR</span></p>
                            </div>
                            <div class="input-group mb-3">
                                <input type="number" class="form-control" placeholder="0.00" onChange={handlechange} value={stakeval} />
                                <div class="input-group-text" onClick={() => {
                                    setMaxVal(stakebalnce);
                                }}>Max</div>
                            </div>
                            {!isEmpty(error) ? <span className='errortext'>{error}</span> : ''}
                            <div className="btnflx" >
                                <button class="primary_btn" onClick={hideModal}>CANCEL</button>
                                {load == false ?
                                    <button class="primary_btn" onClick={stakeTokensubmit}>CONFIRM</button>
                                    :
                                    <button class="primary_btn" disabled>Processing...</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Unstake modal */}
            <div
                className="modal fade primary_modal stakemodal"
                id="stakemodal1"
                data-bs-backdrop="static"
                tabIndex={-1}
                aria-labelledby="stakemodal1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="stakemodal1">
                                Unstake Tokens
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body buytoken mt-0">
                            <div className="label">
                                {/* {
                                    CurrDate < unstakeTimer ? */}
                                {/* <Countdown date={`${unstakeTimer}`} renderer={renderer} /> */}
                                {
                                    parseFloat(unstakebalnce) > 0 &&
                                        UnstakeUserStatus == false && showTimer == true ? <Countdown
                                        date={`${unstakeTimer}`}
                                        renderer={renderer}
                                        onComplete={() => {
                                            getStopTime()
                                        }}
                                    /> : ""
                                }

                                {/* : ''
                                } */}
                                <p className="text-end" >Balance:   <span>{parseFloat(unstakebalnce)} QRR</span></p>
                            </div>
                            {/* <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="0.00" value={stakeval} onChange={handlechange} />
                                <div class="input-group-text">Max</div>
                                </div> */}
                            <div className="btnflx" >
                                <button class="primary_btn" onClick={hideModal1}>CANCEL</button>

                                {load == false ?
                                    <button class="primary_btn" onClick={unstakeTokensubmit}>CONFIRM</button>
                                    :
                                    <button class="primary_btn" disabled>Processing...</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RecentTransactionnModal
                transactionHash={transactionHashh}
            />
        </div>
    )
}

export default StakeModal;