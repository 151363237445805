import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Countdown Timer
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  // Countdown Timer
  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span className="num num1">
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>

        <span className="num num2">
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>

        <span className="num num3">
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>

        <span className="num num4">
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Seconds</span>
        </span>
      </div>
    );
  };

  $(document).ready(function () {
    $("button").click(function () {
      $(".sidebar").addClass("menu_show");
    });
  });

  const banslide = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };
  return (
    <div>
      {/* <ScrollToTopOnMount /> */}
      <Navbar />


      <div className="page_header" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="0" id="trigger-about">
        <div className="container">
          <div className="banner">
            <h1>QRR TOKEN (BEP20) Quantum Rear Resistance Quantum Computing With AI and Blockchain</h1>
            <p className="paratag">QRR TOKEN exploring the possibilities of blockchain in various applications</p>
            <p className="paratag">QRR Blockchain has commenced an advanced R&D and investments programme in the dynamic world of Blockchain Technology, which contains of QRR tokens on advanced AI blockchain applications.</p>
            <p className="paratag">QRR’s R&D team aim is to build a quantum technology enabled AI blockchain and Cryptography; bringing together the most advanced implementation techniques and functions, along with quantum computing technologies and AI, to develop a new and disruptive approach to blockchain technology.</p>
            <p className="paratag">Our programme is focused on most innovative and secured start-ups in the Blockchain and cryptocurrencies industry, with whom we will work and go hand in hand to develop exciting synergies.</p>
            <div className="btnbdr mb-2">
              {/* <button className="primary_btn">Buy Token </button> */}
              <Link className="primary_btn" to={'/buytoken'} >Buy Token </Link>
            </div>&nbsp;&nbsp;
            <div className="btnbdr">
              <Link className="primary_btn" to={'/Staking'} >Staking</Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100" id="trigger-about" >
        <div className="row">
          <div className="col-md-12 col-lg-9 m-auto">
            <div className="presale_box">
              <h2>Lorem Ipsum Lorem Ipsum</h2>
              <p>Lorem ipsum dolor</p>
              <Countdown date={`2023-03-30T20:00:59`} renderer={renderer} />
            </div>
          </div>
        </div>
      </div> */}
    
      <section className="table_sec" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100" id="trigger-about">
      <div className="container">
        <div className="head" >
        <h2 className="h2tag" >QRR TOKEN ICO SALES</h2>
        </div>

        <div className="row">
        <div className="col-md-10 mx-auto">
        <div className="table-responsive">
          <table class="table">
  <thead>
    <tr>
      <th scope="col">Period</th>
      <th scope="col">Bonus tokens</th>
      <th scope="col">Token Price</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>From 21st  April -  June 4th 2023 ( 45Days)</td>
      <td>25%</td>
      <td>$ 0.001</td>
    </tr>
    <tr>
      <td>From 5th    June -   Aug  3rd 2023 ( 60 Days)</td>
      <td>15%</td>
      <td>$ 0.003</td>
    </tr>
    <tr>
      <td>From 4th   Aug –   Oct  18th2023 ( 75 Days)</td>
      <td>10%</td>
      <td>$ 0.006</td>
    </tr>
  </tbody>
          </table>
       </div>
        </div>
        </div>
        
      </div>
      </section>


      <section className="table_sec" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100" id="trigger-about">
      <div className="container">
        <div className="head">
        <h2 className="h2tag">AUDIT : </h2>
       
        <h5><img src={require("../assets/images/techrate.png")} className="img-fluid" alt="img" /> Techrate /
        <img src={require("../assets/images/certik.png")} className="img-fluid certik" alt="img" />Cretik</h5>
        </div>

        <div className="row">
        <div className="col-lg-6 d-flex">
          <div className="box">
            <h5 className="h5t" > MARKET PLACE : </h5>
            <ul>
              <li><img src={require("../assets/images/m1.png")} className="img-fluid" alt="img" />Coinmarketcap</li>
              <li><img src={require("../assets/images/m2.png")} className="img-fluid" alt="img" />Coingecko</li>
              <li><img src={require("../assets/images/m3.png")} className="img-fluid" alt="img" />Crypto compare</li>
              {/* <li>Btc manager</li> */}
              <li><img src={require("../assets/images/m4.png")} className="img-fluid" alt="img" />Coin Gape</li>
              <li><img src={require("../assets/images/m5.png")} className="img-fluid" alt="img" />Crypto idol</li>
            </ul>
          </div>         
        </div>
        <div className="col-lg-6 d-flex">
        <div className="box">
            <h5 className="h5t">LISTING  EXCHANGES :</h5>
            <h5>Aug 20th 2023 ON TOP 5 EXCHANGES</h5>
           <ul>
          <li><img src={require("../assets/images/e1.png")} className="img-fluid" alt="img" />COINBASE</li>
          <li><img src={require("../assets/images/e2.png")} className="img-fluid" alt="img" />POLONIEX</li>
          <li><img src={require("../assets/images/e3.png")} className="img-fluid" alt="img" />BITFINEX </li>
          <li><img src={require("../assets/images/e4.png")} className="img-fluid" alt="img" />HUOBI </li>
          <li><img src={require("../assets/images/e6.png")} className="img-fluid hit" alt="img" />Hitbtc</li>          
          </ul>
         
        </div>
        </div>
        </div>
        <p className="note">2024 listing on <img src={require("../assets/images/e5.png")} className="img-fluid" alt="img" /> Binance exchange expected price up to <span> 10X</span></p>
      </div>
      </section>

      <section className="table_sec stake_table" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100" id="trigger-about">
      <div className="container">
        <div className="head" >
        <h2 className="h2tag" >STAKING </h2>
        </div>

        <div className="row">
        <div className="col-md-10 mx-auto">
        <div className="table-responsive">
          <table class="table">
  <thead>
    <tr>
      <th scope="col">DURATION</th>
      <th scope="col">STAKING BONUS</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>120 DAYS </td>
      <td>6 %</td>
    </tr>
  </tbody>
          </table>
       </div>
        </div>
        </div>
        
      </div>
      </section>

      <section className="buy_token py-4 py-lg-5" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200" id="trigger-about">
        <div className="container">
          <img
            src={require("../assets/images/img_.png")}
            className="img-fluid"
            alt="img"
          />
          <div className="head">
            <h2 className="h2tag">Products & Services</h2>
            <p className="paratag">While QRR's main goal is to develop disruptive quantum supportive AI blockchain systems to have competitive advantage in its multiple utility activities, selected partners will have access to proprietary application software, as well as data services.</p>
          </div>
        </div>
      </section>

      <section className="features featureschg1 features_hvr  py-4 py-lg-5" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" id="trigger-about">
        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="outbox">
                <div className="img_box">
                  <img
                    src={require("../assets/images/b11.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <h5 className="h5tag">OUR TECHNOLOGY</h5>
                  <p className="paratag">Quantum blockchain will have a wide variety of applications and research avenues in the future due to its properties of quicker processing speed and safer transactions based on quantum mechanics.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="outbox">
                <div className="img_box">
                  <img
                    src={require("../assets/images/b11.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <h5 className="h5tag">OUR STRATEGY</h5>
                  <p className="paratag">Exploring Blockchain Technology and Enterprise Resource Planning System: Business and Technical Aspects, Current Problems, and Future Perspectives with in-house R&D team to buil a comprehensive ecosystem</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="outbox">
                <div className="img_box">
                  <img
                    src={require("../assets/images/b11.png")}
                    className="img-fluid"
                    alt="img"
                  />
                </div>
                <div className="box">
                  <h5 className="h5tag">OUR EXECUTION</h5>
                  <ul className="" >
                    <li><p className="paratag">Step 1: Create Tasks. ...</p></li>
                    <li><p className="paratag">Step 2: Setup the Timeline. ...</p></li>
                    <li><p className="paratag">Step 3: Assign tasks to team. ...</p></li>
                    <li><p className="paratag">Step 4: Track Progress. ...</p></li>
                    <li><p className="paratag">Step 5: Communicate Regularly. ...</p></li>
                    <li><p className="paratag">Step 6: Update Stakeholders. .</p></li>
                    <li><p className="paratag">Step 7: Celebrate Milestones. ...</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grid_sec" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" id="trigger-about">
        <div className="container">
          <div className="head">
            <h2 className="h2tag">TOKENOMICS</h2>
          </div>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="box">
                <div>
                  <p>Token Name</p>
                  <p className="paratag">QRR</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="box">
                <p>Ticker Name</p>
                <p className="paratag">QRR</p>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="box">
                <p>Blockchain Network</p>
                <p className="paratag">BEP-20</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-lg-5 text-center" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" id="trigger-about">
        <div className="container">
          <div className="head mb-5">
            <h2 className="h2tag">QRR Tokenomics</h2>
          </div>

          <img
            src={require("../assets/images/piechart.png")}
            className="img-fluid"
            alt="img"
          />
        </div>
      </section>

      <section className="features features_chg   py-4 py-lg-5" >
        <div className="container">
          <div className="head mb-5 pb-5">
            <p className="paratag">QRR Quantum AI and Blockchain implementation, makes foster a culture of innovation and collaboration</p>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">
                        Successful Quantum AI and blockchain adoption starts with a clear vision of how Quantum AI and block chain can be used to solve business problems or create new opportunities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">
                        QRR have a deep understanding of Quantum AI and blockchain technologies potential applications in their industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1700" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">
                        QRR develop a comprehensive Quantum AI strategy that aligns with their organization's goals and objectives. This strategy should cover everything from data management and governance to selecting the right AI and blockchain tools for their platforms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="2000" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">
                        Successful AI and blockchain adoption requires significant investment in people, processes, and technology. QRR willing to invest in hiring top talent, building data infrastructures, and acquiring the necessary AI tools and platforms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1700" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">
                        Communication: QRR Team is ready to communicate the benefits of Quantum AI and blockchain adoption to all stakeholders, including business partners customers, and investors. They should be transparent about the risks and challenges of AI and blockchain adoption how they plan to mitigate them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="outbox" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="2000" id="trigger-about">
                    <div className="img_box">
                      <img
                        src={require("../assets/images/b11.png")}
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                    <div className="box">
                      <div className="above">
                        {/* <h5 className="mb-0">Lorem 1</h5>
                        <p className="my-0">Lorem Ipsum</p> */}
                      </div>
                      {/* <h5 className="h5tag">Lorem Ipsum</h5> */}
                      <p className="paratag">Collaboration: Quantum AI and blockchain adoption QRR team is collaborated between various teams within an organization, including data scientists, IT professionals, and business leaders. QRR facilitate collaboration and ensure that everyone is working towards the same goals.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* rodamap */}
      {/* <section className="features featureschg1 featureschg2   py-4 py-lg-5" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" id="trigger-about">
        <div className="container">
          <div className="head mb-5">
            <h2 className="h2tag">ROAD MAP</h2>
          </div>
          <Slider {...banslide}>
            <div className="" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">MARCH 2023</h5>
                    <p className="paratag">IDEA – Experience team of developers and marketers plan to execute Qauntum AI and Blockchain in the name of Qauntum Rear Resistance ( QRR)</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="150" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">APRIL 2023</h5>
                    <p className="paratag">ICO Begins</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JULY 2023</h5>
                    <p className="paratag">QRR Dectralised Wallet</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="250" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2024</h5>
                    <p className="paratag">NFT Market Place</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JUNE 2024</h5>
                    <p className="paratag">METAVERSE ( Decetraland )</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2025</h5>
                    <p className="paratag">QRR Token Listing on Exchanges</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JUNE 2025</h5>
                    <p className="paratag">Q Blockchain</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2026</h5>
                    <p className="paratag">Quantum AI Blockchain Smart Contract application for Multiple useincase</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JUNE 2026</h5>
                    <p className="paratag">Medical Data App</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">OCT 2026</h5>
                    <p className="paratag">Virtual Reality Apllication</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2027</h5>
                    <p className="paratag">QRR Listing On NASDAQ</p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section> */}

      <section className="features featureschg1 featureschg2   py-4 py-lg-5" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500" id="trigger-about">
        <div className="container">
          <div className="head mb-5">
            <h2 className="h2tag">ROAD MAP</h2>
          </div>
          <Slider {...banslide}>
            <div className="" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">MAY 2023 – ICO SALES BEGINS</h5>
                    <p className="paratag">ICO Sales begins for companies and individuals to increase and consider as a way to raise capital or participate and to invest in QRR projects to get multifold profit in future.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="150" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2024 - LAUNCHING QRR DECENTRALISED WALLET</h5>
                    <p className="paratag">QRR Wallet launching a decentralized exchange (DEX) is a digital currency exchange that allows users to buy crypto through direct, peer-to-peer crypto currency transactions, all over a online platform without an intermediary.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JULY 2023</h5>
                    <p className="paratag">QRR Dectralised Wallet</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="250" id="trigger-about">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">APRIL 2024 - LAUNCHING QRR PUBLIC EXCHANGE</h5>
                    <p className="paratag">QRR Crypto Exchange : Buy and Sell Bitcoin, Ether and More The QRR crypto exchange enable you to trade coins and tokens easily while keeping your assets safe and your fees low.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JULY 2024 – METAVERSE</h5>
                    <p className="paratag">QRR metaverse technology where people can interact each other with virtual products and services in a single seamless experience. The metaverse provides users with a virtual environment where they can explore interact and purchase products and services, making it a new platform for e-commerce, Real estate, Gaming, Entertainment, Medical, Education and much more services to launch in period of time.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JANUARY - 2025 NFT</h5>
                    <p className="paratag">Individuals can use QRR NFTs to represent ownership of digital assets, such as artwork, music, or videos. This would help reduce piracy and ensure that creators are properly compensated for their work. QRR NFTs can also be used to represent ownership of physical assets, such as cars or real estate. This would create a new market for these assets and could help individuals to monetize them in new ways.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JUNE 2025 - LAUNCHING QRR PRIVATE QUANTUM BLOCKCHAIN </h5>
                    <p className="paratag">QRR Quantum Blockchain to create functioning Blockchain technology capable of resisting the currently emerging threats from Quantum Computer, The QRR will initiate and take a radical step of post-quantum algorithms; it will use the latest achievements of quantum cryptography to create provably secure solutions, which enhance data protection, integrity and processing speed.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2026</h5>
                    <p className="paratag">Quantum AI Blockchain Smart Contract application for Multiple useincase</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JUNE 2026</h5>
                    <p className="paratag">Medical Data App</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mar">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">OCT 2026</h5>
                    <p className="paratag">Virtual Reality Apllication</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="outbox">
                <div className="inbox">
                  <div className="box">
                    <h5 className="h5tag">JAN 2027</h5>
                    <p className="paratag">QRR Listing On NASDAQ</p>
                  </div>
                </div>
              </div>
            </div> */}
          </Slider>
        </div>
      </section>

      <section className="faq_section  py-4 py-lg-5" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="2000" id="trigger-about">
        <div className="faq_wrap">
          <div className="container">
            <div className="faq_bg">
              <div className="head">
                <h2 className="h2tag">FAQ</h2>
                {/* <p className="paratag">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore{" "}
                </p> */}
              </div>

              <div className="row">
                <div className="col-md-12 col-lg-6 m-auto">
                  <div class="accordion" id="faq_accodian">
                    <div
                      class="accordion-item collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseone"
                      aria-expanded="false"
                      aria-controls="collapseone"
                    >
                      <h2 class="accordion-header" id="headingone">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                        >
                          <p className="paratag">What is QRR Token?</p>
                        </button>
                      </h2>
                      <div
                        id="collapseone"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingone"
                        data-bs-parent="#faq_accodian"
                      >
                        <div class="accordion-body">
                          <p className="paratag">
                            QRR tokens are created for the utilization on quantum AI blockchain’s in various applications.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="accordion-item collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                        >
                          <p className="paratag">How to Buy QRR Tokens?</p>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#faq_accodian"
                      >
                        <div class="accordion-body">
                          <p className="paratag">
                            Connect your trust wallet or metamask wallet and type the url on dap browser.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="accordion-item collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                        >
                          <p className="paratag">Where will I receive my QRR purchase Token?</p>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#faq_accodian"
                      >
                        <div class="accordion-body">
                          <p className="paratag">
                            If you have purchase QRR tokens on trustwallet you receive in trust wallet,
                            If you have purchased on metamask you will receive in metamask wallet.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="accordion-item collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefour"
                      aria-expanded="false"
                      aria-controls="collapsefour"
                    >
                      <h2 class="accordion-header" id="headingfour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                        >
                          <p className="paratag">How to trade QRR Tokens?</p>
                        </button>
                      </h2>
                      <div
                        id="collapsefour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingfour"
                        data-bs-parent="#faq_accodian"
                      >
                        <div class="accordion-body">
                          <p className="paratag">
                          Once QRR tokens are listed on exchanges you can start trading QRR tokens.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="accordion-item collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                    >
                      <h2 class="accordion-header" id="headingfive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                        >
                          <p className="paratag">What is the benefit of staking QRR tokens?</p>
                        </button>
                      </h2>
                      <div
                        id="collapsefive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingfive"
                        data-bs-parent="#faq_accodian"
                      >
                        <div class="accordion-body">
                          <p className="paratag">
                          Once the QRR tokens started for utilization in various applications the price of the tokens will make you 20X to 30X profit.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="accordion" id="faq_accodian">
  <div class="accordion-item" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" >
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>
            */}
                </div>
              </div>

              <div className="text-center">
                <div className="btnbdr">
                  <button className="primary_btn">Read More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>
  );
}
