import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import $ from "jquery";
import WalletModal from "../pages/WalletModal";
import { useDispatch, useSelector } from "react-redux";
import { connection } from "../helper/connection";
import { formatAddress, toFixedFormat } from "../helper/customMath";
import { setWallet } from "../reducers/Actions";
import Web3Modal from 'web3modal'
import { providerOptions } from "../config/providerOptions";
import config from "../config/config"
import { type PublicClient, type WalletClient, useWalletClient, useAccount, usePublicClient, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi'
import { useEthersSigner, walletClientToSigner } from "../pages/ethersconnect"
import { connectionNew } from "../helper/connectionnew";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert";

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const [balance, setbalance] = useState("")
  const [useraddress, setuseraddress] = useState("");
  const walletConnection = useSelector((state) => state.walletConnection)
  const { disconnect, isSuccess, status } = useDisconnect()
  const { chain } = useNetwork()
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId })
  const network = useSwitchNetwork()


  useAccount({

    onDisconnect: () => {
      localStorage.clear();
      console.log('Disconnected')
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
    },
  }
  )
  useEffect(() => {
    if (isSuccess == true) {
      setTimeout(() => {
        localStorage.clear()
        window.location.reload(false)
      }, 500)
    }
  }, [isSuccess])

  // const { address, isConnecting, isDisconnected } = useAccount()



  useEffect(() => {
    if (chain && (chain.id !== config.NetworkId)) {
      dispatch(setWallet({
        network: "",
        web3: "",
        address: "",
        provider: "",
        connect: "",
        isChange: "true"
      }));
    } else {
      dispatch(setWallet({
        network: chainId,
        web3: walletConnection.web3,
        address: walletConnection.address,
        provider: walletConnection.provider,
        connect: "yes",
        isChange: "false"
      }))
      // window.location.reload(false)
    }
  }, [walletClient, chain?.network])


  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    // $(document).ready(function () {
    //   $(".main_navbar ul.navbar-nav li a").click(function () {
    //     $(".navbar-toggler").trigger("click");
    //   });
    // });
  }



  useEffect(() => {
    setConnection()
  }, [walletConnection.network, walletClient])


  useEffect(() => {
    getuserBalance();
  }, [walletConnection, walletClient]);

  async function setConnection() {

    if (chain && chain.id != config.NetworkId) {
      var errorMsg = "Please select " + config.networkName + " on your wallet"
      toastAlert('error', errorMsg, 'network');
      setTimeout(function () {
        disconnect()
      }, 1000);
    }
    if (walletClient && chain && (chain.id == config.NetworkId)) {

      var { signer, transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);

      console.log('userdetails: ', {
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false",
        // userdetails: userdetails
      });

      dispatch(setWallet({
        network: config.NetworkId,
        web3: web3,
        address: walletClient.account.address,
        provider: transport,
        connect: "yes",
        isChange: "false",
      }));
      getuserBalance();
      var get = await connectionNew()
      // console.log('gesssssssssssssssssst: ', get);
      // console.log(signer, transport, '@@@@@@@@@@@@@@@@@@@1')
    }

  }
  async function getuserBalance() {
    if (walletConnection && walletConnection.web3 && walletConnection.address && walletConnection.address != "") {
      setuseraddress(walletConnection.address);
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      var bal = getBalance / 10 ** 18;
      bal = await toFixedFormat(bal);
      setbalance(bal);
    }
  }

  const handleClick = async (event) => {
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <>
      <nav className="navbar navbar-expand-sm bg-light main_navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid"
              alt="Icon"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
            <div className="menu_icon">
              <i class="fas fa-bars"></i>
            </div>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_right">


              <li class="nav-item">

                {walletConnection && walletConnection.connect == "yes" && walletConnection.address && walletConnection.address != "" ? (

                  <div className="dropdown me-3">
                    <button
                      className="primary_btn dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    // data-bs-target="#connect_wallet_modal"
                    >
                      {formatAddress(useraddress)}
                    </button>
                    <ul
                      className="dropdown-menu walletdrop "
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link to={'/buytoken'} className="dropdown-item">Buy Token </Link>
                      </li>
                      <li>
                        <Link to={'/Staking'} className="dropdown-item">Staking</Link>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#" onClick={() => disconnect()}
                        >
                          Disconnect
                        </a>
                      </li>

                    </ul>
                  </div>
                ) : (
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >Connect the Wallet</button>
                )}




                {/* 
                {walletConnection && walletConnection.connect == "yes" && walletConnection.address && walletConnection.address != "" ?
                  <div className="text-center d-flex justfiy-center">
                    <div class="btnbdr">
                      <button
                        className="primary_btn"
                        data-bs-toggle="modal"
                        data-bs-target="#connect_wallet_modal"
                        onClick={handleClick}>{formatAddress(useraddress)}</button>
                      <ul class="dropdown-menu walletdrop" aria-labelledby="dropdownMenuLink" data-bs-popper="static">
                       
                        <li><a class="dropdown-item" href="#" onClick={() => disconnectWeb3Wallet()}>Logout</a></li>
                      </ul>
                    </div>
                  </div>
                  : <li class="nav-item">
                    <div className="text-center d-flex justfiy-center">
                      <div class="btnbdr">
                        <button
                          className="primary_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >Connect the Wallet</button>
                      </div>
                    </div>
                  </li>} */}

              </li>










              {/* <li>
                <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button>
              </li> */}
            </ul>
            {/* <div className="menu_icon">
            <i class="fas fa-bars"></i>
          </div> */}
          </div>
        </div>
      </nav>
      {/* Connect Wallet Modal */}
      <WalletModal />
    </>
  );
}
