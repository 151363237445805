import React from "react";
import config from '../config/config'

export default function Footer(props) {

  // const [email, setemail] = useState("");

  // const mailchange = (e) => {
  //   setemail(e.target.value)
  // }

  // async function subscribe(){
  //   if(email && email!=null && email!=undefined && email!=""){
  //      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //       if(!email || regex.test(email) === false){
  //           toastAlert('error', "Enter Valid Email Id", 'network');
  //       }else{
  //         let data = {
  //           "email": email
  //         }
  //         let rest = await subscribe_now(data)
  //         if(rest && rest.data && rest.data.message){
  //           toastAlert('success',rest.data.message, 'network');
  //           setemail("");
  //         }
  //       }
  //   }else{
  //     toastAlert('error', "Enter Valid Email Id", 'network');
  //   }
  // }

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">

          <div className="footer_panel_top">
            <div className="row">

              <div className="col-md-12">

                {/* <h2 className="h2tag" >Don't miss out, Be the first to know</h2> */}
                {/* <p className="paratag" >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> */}
                <ul className="footer_social_links">

                  <li>
                    <a href="/" target="_blank">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>

                  <li>
                    <a href="/" target="_blank">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/" target="_blank">
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>

                </ul>

                <div className='footer_panel_left'>
                  <p className='footer_panel_link'>Smart-contract address: </p>
                  <p className="mb-1"><label>QRR Token :</label> <a href={config.txUrlAddress + config.QuantumToken} target="_blank">{config.QuantumToken}</a></p>
                  <p className="mb-1"><label>QRR ICO :</label> <a href={config.txUrlAddress + config.QUANTUMICO} target="_blank">{config.QUANTUMICO}</a></p>
                  {/* <p>Copy right © {new Date().getFullYear()} <a href="#">DeFiQ</a>, All rights Reserved</p> */}

                </div>
              </div>
              {/* <div className="col-md-6 m-auto">
            <div class="input-group">
              <input
                type="email"
                class="form-control"
                aria-label="Email Address"
                aria-describedby="email"
                placeholder="Enter your email address"
              />
              <div className="btnbdr">
              <button type="button" class="input-group-text primary_btn" ><span className="">Send Now</span></button>
              </div>
            </div>     
            </div> */}
            </div>




          </div>













          <div className="footer_panel_bottom">
            <ul className="footer_list_links">
              <li><a href="#">Terms & Conditions </a></li>
              <li><a href="#">Risk Disclaimer </a></li>
              <li><a href="#"> Privacy Policy</a></li>
            </ul>
            <p>Copyright © {new Date().getFullYear()} quantum Rear Resistance, All rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
