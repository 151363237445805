import React from 'react'
import config from '../config/config'

const RecentTransactionnModal = (props) => {
    var transHash = props.transactionHash;

    const hideModal = async () => {
        window.$("#recent_trans_modal").modal("hide");
    }

    return (
        <div>
            {/* RecentTransaction modal */}
            <div
                className="modal fade primary_modal stakemodal"
                id="recent_trans_modal"
                data-bs-backdrop="static"
                tabIndex={-1}
                aria-labelledby="recent_trans_modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="recent_trans_modal">
                                Recent Transactions
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body buytoken mt-0">
                            <a href={config.txurl + transHash} target="_blank" className="text-white d-flex align-items-center justify-content-center mb-3" rel="noopener noreferrer">
                                <p>View Transaction</p>
                            </a>
                            <div className="btnflx" >
                                <button class="primary_btn" onClick={hideModal}>CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentTransactionnModal;