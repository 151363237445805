import QuantumIcoabi from '../ABI/QuantumIcoabi'
import Quantumtokenabi from '../ABI/Quantumtokenabi.json'
import stakingabi from '../ABI/Stakingabi.json'
import config from '../config/config'
import { convert } from './convert'
import { allowance, allowanceQRR } from '../action/contractActions'
import { toFixedFormatValue } from './customMath'
import isEmpty from 'is-empty'
// import moment from 'moment'
import JSBI from 'jsbi/dist/jsbi.mjs';

import { connection } from './connection'

export async function getTokenfromBusd(value, currency) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            if (currency == 'BUSD') {
                if (!isEmpty(value)) {
                    let Contractsss = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
                    let amount = parseFloat(value) * (10 ** 18)
                    amount = await convert(amount)
                    amount = amount.toString()
                    var checkDeci = amount.split('.')
                    if (checkDeci.length === 2) {
                        amount = checkDeci[0]
                    }
                    let perToken = await Contractsss.methods.getTokenfromBusd((amount).toString()).call();
                    perToken = perToken / 1e18;
                    perToken = await toFixedFormatValue(parseFloat(perToken));
                    return {
                        status: true,
                        perToken: perToken,
                        currency: currency
                    }
                }
                else {
                    return {
                        status: true,
                        // perToken: 0,
                        perToken: '',
                        currency: currency
                    }
                }
            }
        } else {
            return {
                status: true,
                perToken: 0,
                currency: currency
            }
        }
    }
    catch (err) {
        return {
            status: false,
            perToken: 0,
            currency: currency
        }
    }
}

export async function getTokenfromBnb(value, currency) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            if (currency == 'BNB') {
                if (!isEmpty(value)) {
                    let Contractsss = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
                    let amount = (parseFloat(value) * (10 ** 18))
                    amount = await convert(amount)
                    amount = amount.toString()
                    var checkDeci = amount.split('.')
                    if (checkDeci.length === 2) {
                        amount = checkDeci[0]
                    }
                    let perToken = await Contractsss.methods.getTokenfromBnb(amount.toString()).call();
                    perToken = perToken / 1e10
                    perToken = await convert(perToken)
                    perToken = await toFixedFormatValue(parseFloat(perToken));
                    return {
                        status: true,
                        perToken: perToken,
                        currency: currency
                    }
                } else {
                    return {
                        status: true,
                        // perToken: 0,
                        perToken: '',
                        currency: currency
                    }
                }
            }
            else {
                return {
                    status: true,
                    perToken: 0,
                    currency: currency
                }
            }
        }
    }
    catch (err) {
        return {
            status: false,
            perToken: 0,
            currency: currency
        }
    }
}

export async function getPriceBNB(amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            const Contract = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
            let price = await Contract.methods.getBnbPrice().call();
            let tokenbusd = await Contract.methods.getTokenprice().call();
            let last_0 = parseFloat(amount) / (tokenbusd / 1000000)
            let last = parseFloat(last_0 / (price / 1e16)).toFixed(4);
            let saved_one = parseFloat(last_0 / (price / 1e16))
            saved_one = await convert(saved_one)
            saved_one = await toFixedFormatValue(parseFloat(saved_one));

            return {
                inputCal: saved_one,
                inputvalue: last
            }
        }
        else {
            return {
                inputCal: 0,
                inputvalue: 0
            }
        }

    }
    catch (err) {
        return {
            inputCal: 0,
            inputvalue: 0
        }

    }
}

export async function getPriceBUSD(amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            let Contract = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
            let price = await Contract.methods.getTokenprice().call();
            let cal_price_0 = parseFloat(amount) / (parseFloat(price) / 1000000)
            cal_price_0 = await toFixedFormatValue(parseFloat(cal_price_0));
            let cal_price = parseFloat(cal_price_0).toFixed(4)

            return {
                inputCal: cal_price_0,
                inputvalue: cal_price
            }
        }
        else {
            return {
                inputCal: 0,
                inputvalue: 0
            }
        }
    }
    catch (err) {
        return {
            inputCal: 0,
            inputvalue: 0
        }
    }
}

export async function depositBUSD(tokenname, amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            let Contractsss = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
            let sendAmt = amount * (10 ** 18)
            sendAmt = await convert(sendAmt)
            sendAmt = sendAmt.toString()
            var checkDeci = sendAmt.split('.')
            if (checkDeci.length === 2) {
                sendAmt = checkDeci[0]
            }
            let perToken = await Contractsss.methods.depositBUSD(sendAmt.toString()).send({ from: address })
            return perToken
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function depositBNB(tokenname, amount) {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            var address = get.address;
            let Contractsss = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
            let sendAmt = amount * (10 ** 18)
            sendAmt = await convert(sendAmt)
            sendAmt = sendAmt.toString()
            var checkDeci = sendAmt.split('.')
            if (checkDeci.length === 2) {
                sendAmt = checkDeci[0]
            }
            let perToken = await Contractsss.methods.depositBNB().send({ from: address, value: sendAmt.toString() })
            return perToken
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function checkAllowance(amount, address, balance, symbol, decimals) {
    try {
        var fromallowance = "no";
        var frominsuffucient = "no";
        var amount = parseFloat(amount) * (10 ** 18)
        var value1 = await allowance(address, config.QUANTUMICO);
        var bal = value1.value;
        var balanceof = parseFloat(balance.balanceOf)
        balanceof = parseFloat(balanceof) / 10 ** parseFloat(decimals);
        bal = parseFloat(bal) / 10 ** parseFloat(decimals);
        amount = parseFloat(amount) / 10 ** parseFloat(decimals);
        if (amount > 0) {
            if (parseFloat(bal) < parseFloat(amount) && symbol !== config.BNBSYMBOL) {
                fromallowance = "yes";
            }
            if (parseFloat(amount) > parseFloat(balanceof)) {
                frominsuffucient = "yes";
            }
            return {
                fromallowance,
                frominsuffucient,
            }
        }
        else {
            return {
                fromallowance,
                frominsuffucient,
            }
        }
    }
    catch (err) {
        return {
            fromallowance: "no",
            frominsuffucient: "no"
        }
    }
}

export async function getAdminBalance() {
    var get = await connection();
    try {
        if (get && get.web3) {
            var web3 = get.web3;
            const tokenContract = new web3.eth.Contract(Quantumtokenabi, config.QuantumToken);
            var adminbal = await tokenContract.methods.balanceOf(config.QUANTUMICO).call();
            adminbal = parseFloat(adminbal) / 10 ** 18
            return adminbal
        }
        else {
            return 0
        }
    }
    catch (err) {
    }
}

export async function getAllowanceDetails() {
    try {
        let { value, status } = await allowanceQRR(config.StakingContract);
        let values = parseFloat(value)
        values = values / 10 ** 18
        if (status == true) {
            return values
        }
        else {
            return 0
        }
    }
    catch (err) {
    }

}

export async function staking(amount, stakeBal) {
    let get = await connection()
    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            var amountt = parseFloat(amount)
            var unstake = amountt * (10 ** 18)
            unstake = await convert(unstake)
            unstake = unstake.toString()
            var checkDeci = unstake.split('.')
            if (checkDeci.length === 2) {
                unstake = checkDeci[0]
            }
            const tokenContract = new web3.eth.Contract(Quantumtokenabi, config.QuantumToken);
            var balance = await tokenContract.methods.balanceOf(address).call();
            // balance = parseFloat(balance);
            // unstake = parseFloat(unstake)
            if (parseInt(balance) < parseInt(unstake)) {
                return {
                    status: false,
                    message: 'Insufficient balance'
                }
            }
            else {
                var contract = new web3.eth.Contract(stakingabi, config.StakingContract)

                if (parseFloat(amount) == parseFloat(stakeBal)) {

                    var QrrContract = new web3.eth.Contract(Quantumtokenabi, config.QuantumToken);
                    var QrrbalanceOf = await QrrContract.methods.balanceOf(address).call();

                    unstake = JSBI.BigInt(QrrbalanceOf);
                    unstake = String(unstake);

                }

                var data = await contract.methods.stake(unstake.toString()).send({ from: address })
                if (data && (data.status == true) && (!isEmpty(data.transactionHash))) {
                    return {
                        status: true,
                        message: "Staked Successfully",
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        message: "Transaction rejected",
                        transactionHash: ''

                    }
                }
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }
    }
}

export async function unstaking(UnstakeUserStatus) {
    var get = await connection()
    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
            var { lastInvestTimestamp, amount } = await contract.methods.userInfo(address).call();
            if (parseFloat(amount) <= 0) {
                return {
                    status: false,
                    message: "QRR balance is too Low"
                }
            }
            var lockperiod = await contract.methods.lockingPeriod().call();
            var uniqueLockperiod = await contract.methods.checklocktime(address).call();
            var unstakeTime = '';
            if (parseFloat(uniqueLockperiod) > 0) {
                unstakeTime = parseInt(lastInvestTimestamp) + parseInt(uniqueLockperiod);
                unstakeTime = unstakeTime * 1000;
                unstakeTime = new Date(unstakeTime);
            }
            else {
                unstakeTime = parseInt(lastInvestTimestamp) + parseInt(lockperiod);
                unstakeTime = unstakeTime * 1000;
                unstakeTime = new Date(unstakeTime);
            }
            // setUnstakeTimer(unstakeTime)
            var currDate = new Date()
            if ((unstakeTime > currDate) && UnstakeUserStatus == false) {
                return {
                    status: false,
                    message: "U can't unstake now"
                }
            }
            else if (parseFloat(amount) <= 0) {
                return {
                    status: false,
                    message: "QRR balance is too Low"
                }
            }
            else {
                var data = await contract.methods.unstake().send({ from: address })
                if (data && (data.status == true) && (!isEmpty(data.transactionHash))) {
                    return {
                        status: true,
                        message: "Unstaked Successfully",
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        message: "Transaction rejected",
                        transactionHash: ''
                    }
                }
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: "Transaction rejected",
            transactionHash: ''
        }
    }
}


export async function harvest() {
    try {
        var get = await connection()
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;

            var contract = new web3.eth.Contract(stakingabi, config.StakingContract);
            var pendingRewd = await contract.methods.pendingReward(address).call()
            pendingRewd = parseFloat(pendingRewd) / 10 ** 18
            if (parseFloat(pendingRewd) > 0) {
                var data = await contract.methods.claimReward().send({ from: address })
                if (data && data.status && !isEmpty(data.transactionHash)) {
                    return {
                        status: true,
                        // pendingRewd: pendingRewd,
                        message: 'Reward earned successfully',
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        // pendingRewd: 0,
                        message: 'Transaction Rejected',
                        transactionHash: ''
                    }
                }
            }
            else {
                return {
                    status: false,
                    // pendingRewd: 0,
                    message: 'Reward amount is too low',
                    transactionHash: ''
                }
            }
        } else {
            return {
                status: false,
                message: 'Please connect your wallet',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            pendingRewd: 0,
            message: 'Transaction Rejected',
            transactionHash: ''
        }
    }
}

export async function GetEarnedAmount() {
    var get = await connection()
    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            var contract = new web3.eth.Contract(stakingabi, config.StakingContract);
            var pendingRewd = await contract.methods.pendingReward(address).call()
            pendingRewd = parseFloat(pendingRewd) / 10 ** 18
            // var { amount } = await contract.methods.userInfo(address).call();
            return {
                pendingRewd: pendingRewd,
                // amount: amount
            }
        }
        else {
            return {
                pendingRewd: 0
            }
        }
    }
    catch (err) {
        return {
            pendingRewd: 0
        }
    }
}

export async function StakeTimerFunction(setUnstakeTimer, setShowTimer) {
    var get = await connection()
    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
            var { lastInvestTimestamp } = await contract.methods.userInfo(address).call();
            var lockperiod = await contract.methods.lockingPeriod().call();

            var uniqueLockperiod = await contract.methods.checklocktime(address).call();

            if (parseFloat(uniqueLockperiod) > 0) {
                var unstaketimer = parseInt(lastInvestTimestamp) + parseInt(uniqueLockperiod);
                unstaketimer = unstaketimer * 1000;
                unstaketimer = new Date(unstaketimer);
                var timer = new Date(unstaketimer).toISOString();
                var currtimer = new Date().toISOString();
                setUnstakeTimer(timer)
                if (currtimer < timer) {
                    setShowTimer(true)
                }
                else {
                    setShowTimer(false)
                }
            }
            else {
                var unstakeTime = parseInt(lastInvestTimestamp) + parseInt(lockperiod);
                unstakeTime = unstakeTime * 1000;
                unstakeTime = new Date(unstakeTime);
                var timee = new Date(unstakeTime).toISOString();
                var currTime = new Date().toISOString();
                setUnstakeTimer(timee)
                if (currTime < timee) {
                    setShowTimer(true)
                }
                else {
                    setShowTimer(false)
                }
                // setCurrDate(currTime)
            }
        }
        else {
            setUnstakeTimer('')
            setShowTimer(false)
        }
    }
    catch (err) {

    }
}
export async function Getstakeamount() {
    var get = await connection()
    if (get && get.web3) {
        var address = get.address;
        var web3 = get.web3;
        var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
        var { amount } = await contract.methods.userInfo(address).call();
        amount = amount / 10 ** 18
        var apypercent = await contract.methods.apyPercentage().call();
        apypercent = parseFloat(apypercent) / 100;
        var liquidity = await contract.methods.totalLiqutity().call();
        var totalUnstaKe = await contract.methods.totalUnstake().call();
        var liquidityVal = parseFloat(liquidity) - parseFloat(totalUnstaKe)
        liquidityVal = parseFloat(liquidityVal) / 10 ** 18

        var unstakeUserStatus = await contract.methods.isEligibleUnstake(address).call();

        return {
            amount: amount,
            apypercent: apypercent,
            liquidityVal: liquidityVal,
            unstakeUserStatus: unstakeUserStatus
        }
    }
    else {
        return {
            amount: 0,
            apypercent: 0,
            liquidityVal: 0,
            unstakeUserStatus: ''
        }
    }
}

export async function updateapy(amount) {

    var get = await connection()
    try {

        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            if (get.address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            var amountt = parseFloat(amount)
            var apyval = amountt * (10 ** 2)
            apyval = await convert(apyval)
            apyval = apyval.toString()
            var checkDeci = apyval.split('.')
            if (checkDeci.length === 2) {
                apyval = checkDeci[0]
            }
            var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
            var data = await contract.methods.updateApy(apyval.toString()).send({ from: address })
            if (data && data.transactionHash) {
                return {
                    status: true,
                    message: 'APY updated successfully',
                    transactionHash: data.transactionHash
                }
            }
            else {
                return {
                    status: false,
                    message: 'Transaction rejected',
                    transactionHash: ''
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }

    }
}

export async function updatelockperiod(amount) {
    var get = await connection()

    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            if (get.address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            var amountt = parseFloat(amount)
            var lockvalue = amountt
            lockvalue = await convert(lockvalue)
            lockvalue = lockvalue.toString()
            var checkDeci = lockvalue.split('.')
            if (checkDeci.length === 2) {
                lockvalue = checkDeci[0]
            }
            var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
            var data = await contract.methods.updateLockingPeroid(lockvalue.toString()).send({ from: address })
            if (data && data.transactionHash) {
                return {
                    status: true,
                    message: 'Lockperiod updated successfully',
                    transactionHash: data.transactionHash
                }
            }
            else {
                return {
                    status: false,
                    message: 'Transaction rejected',
                    transactionHash: ''
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }
    }
}


export async function updateTokenPrice(amount) {
    var get = await connection()

    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            if (get.address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            var amountt = parseFloat(amount)
            amountt = 1 / amountt;
            var price = amountt * 10 ** 6
            price = price.toString()
            price = await convert(price)
            var checkDeci = price.split('.')
            if (checkDeci.length === 2) {
                price = checkDeci[0]
            }
            var contract = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO)
            var data = await contract.methods.settoken(price.toString()).send({ from: address })
            if (data && data.transactionHash) {
                return {
                    status: true,
                    message: 'Price updated successfully ',
                    transactionHash: data.transactionHash
                }
            }
            else {
                return {
                    status: false,
                    message: 'Transaction rejected',
                    transactionHash: ''
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }
    }
}
export async function claimrewardsAction() {
    var get = await connection()
    try {
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;

            var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
            var data = await contract.methods.airdropUsers(address).call()
            if (parseFloat(data) == 0) {
                return {
                    status: false,
                    message: 'Claim amount is too low'
                }
            }
            var data = await contract.methods.claimAirdrop().send({ from: address })
            if (data && data.status == true && data.transactionHash) {
                return {
                    status: true,
                    message: 'Reward Claimed successfully'
                }
            }
            else {
                return {
                    status: false,
                    message: 'Transaction rejected'
                }
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction Rejected'
        }
    }
}

export async function updateUnstakeUsersAction(unstakeaddress, statusupdate) {
    var get = await connection()
    try {
        var statuss = true
        var isaddress;
        var items;
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3
            var unstakeaddr = unstakeaddress.split(',')

            if (address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            var datas = unstakeaddr && unstakeaddr.length > 0 && unstakeaddr.map((item) => {
                isaddress = web3.utils.isAddress(item)
                if (isaddress === false) {
                    statuss = false,
                        items = item
                    // return {
                    //     status :false,
                    //     message: 'Invalid address',
                    //     transactionHash: ''
                    // }
                }
                else {
                    statuss = true,
                        items = item

                    // return {
                    //     status :true,
                    //     message: 'valid address',
                    //     transactionHash: ''
                    // }
                }

            })
            // }

            // if (datas && datas[0] && datas[0].statuss && (datas[0].statuss == true)) {
            if (statuss == true) {

                var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
                var data = await contract.methods.updateUnstakeUsers(unstakeaddr, statusupdate).send({ from: address })
                if (data && data.transactionHash) {
                    return {
                        status: true,
                        message: 'Success',
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction Rejected',
                        transactionHash: ''
                    }
                }
            }
            else {
                return {
                    status: false,
                    message: 'Invalid address',
                    transactionHash: ''
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction Rejected',
            transactionHash: ''
        }
    }
}

export async function updateAirdrop(airdropaddress, airdropamt) {
    var get = await connection();
    try {
        var statuss = true
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            if (get.address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            let checkaddrs = airdropaddress.split(',')
            let amountsend = airdropamt.split(',')
            var Amtairdrop = amountsend.map(x => x * (10 ** 18));

            Amtairdrop = await Promise.all(Amtairdrop && Amtairdrop.length > 0 && Amtairdrop.map(async (amt) => {
                return await convert(amt);
            }))

            var datas = checkaddrs && checkaddrs.length > 0 && checkaddrs.map((item) => {
                var isaddress = web3.utils.isAddress(item)
                if (isaddress === false) {
                    statuss = false
                }
                else {
                    statuss = true
                }
            })
            if (statuss == true) {
                var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
                let amount = Amtairdrop.toString().split(',')
                if (checkaddrs.length != amount.length) {
                    return {
                        status: false,
                        message: 'Invalid Details',
                        transactionHash: ''
                    }
                }
                var data = await contract.methods.addAirDropUsers(checkaddrs, amount).send({ from: address })
                if (data && data.transactionHash) {
                    return {
                        status: true,
                        message: 'Success',
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction Rejected',
                        transactionHash: ''
                    }
                }
            }
            else {
                return {
                    status: false,
                    message: 'Invalid address',
                    transactionHash: ''
                }
            }
        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }
    }
}

export async function unstakeUniqueUserLockAction(lockaddress, locktime) {
    try {
        var statuss = true
        var isaddress;
        var items;
        var get = await connection()
        if (get && get.web3) {
            var address = get.address;
            var web3 = get.web3;
            if (get.address !== config.AdminAddress) {
                return {
                    status: false,
                    message: 'Please login to admin address',
                    transactionHash: ''
                }
            }
            var unstakeaddress = lockaddress.split(',');
            var datas = unstakeaddress && unstakeaddress.length > 0 && unstakeaddress.map((item) => {
                isaddress = web3.utils.isAddress(item)
                if (isaddress === false) {
                    statuss = false,
                        items = item
                }
                else {
                    statuss = true,
                        items = item
                }

            })
            var unstakeperiod = locktime.split(',')
            if (unstakeaddress.length != unstakeperiod.length) {
                return {
                    status: false,
                    message: 'Invalid details',
                    transactionHash: ''
                }
            }
            if (statuss == true) {
                var contract = new web3.eth.Contract(stakingabi, config.StakingContract)
                var data = await contract.methods.checkLockperiod(unstakeaddress, unstakeperiod).send({ from: address })
                if (data && data.transactionHash) {
                    return {
                        status: true,
                        message: 'Lockperiod updated successfully',
                        transactionHash: data.transactionHash
                    }
                }
                else {
                    return {
                        status: false,
                        message: 'Transaction rejected',
                        transactionHash: ''
                    }
                }
            }
            else {
                return {
                    status: false,
                    message: 'Invalid address',
                    transactionHash: ''
                }
            }

        }
        else {
            return {
                status: false,
                message: 'Please connect to admin wallet address',
                transactionHash: ''
            }
        }
    }
    catch (err) {
        return {
            status: false,
            message: 'Transaction rejected',
            transactionHash: ''
        }
    }
}