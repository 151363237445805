import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { approveQRRtoken, getQRRBalance } from "../action/contractActions.js";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { claimrewardsAction, getAllowanceDetails, GetEarnedAmount, Getstakeamount, harvest, stake } from "../helper/calculation.js";
import StakeModal from "./StakeModal.js";
import config from '../config/config'
import { toastAlert } from "../helper/toastAlert.js";
import RecentTransactionnModal from "./RecentTransactionModal.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Staking(props) {

  const [approve, setApprove] = useState()
  const [rewardAmt, setEarnedAmt] = useState(0)
  const [stakeamt, setStakeAmount] = useState(0)
  const [apypercent, setapypercent] = useState(0)
  const [liquidityval, setLiquidity] = useState(0)
  const [stakebalnce, setStakebalance] = useState(0)
  const [load, setload] = useState(false)
  const [loadharvest, setloadharvest] = useState(false)
  const [loadclaim, setloadclaim] = useState(false)
  const walletConnection = useSelector((state) => state.walletConnection)

  useEffect(() => {
    getDetails()
    getstakeamount()
  }, [walletConnection])

  useEffect(() => {
    const interval = setInterval(() => {
      getreward()
    }, 3000);
    return () => clearInterval(interval);
  }, [walletConnection]);

  const getreward = async () => {
    var data = await GetEarnedAmount()
    if (data) {
      var rewardAmt = parseFloat(data.pendingRewd)
      rewardAmt = rewardAmt.toFixed(8)
      setEarnedAmt(rewardAmt)
    }
    else {
      setEarnedAmt(0)
      // setStakeAmount(0)
    }
  }
  const getDetails = async () => {
    var values = await getAllowanceDetails()
    var data = await Getstakeamount()
    if (data) {
      var amounts = parseFloat(data.amount)
    }
    if (parseFloat(values) <= 0 && parseFloat(amounts) <= 0) {
      setApprove(false)
    }
    else {
      setApprove(true)
    }
  }

  const getstakeamount = async () => {
    var dataqrr = await getQRRBalance()
    setStakebalance(dataqrr && parseFloat(dataqrr.balance))

    var data = await Getstakeamount()
    if (data) {
      var amounts = parseFloat(data.amount)
      setStakeAmount(amounts)
      var apypercent = parseFloat(data.apypercent)
      setapypercent(apypercent)
      var liquidity = parseFloat(data.liquidityVal)
      setLiquidity(liquidity)
    }
    else {
      setStakeAmount(0)
      setapypercent(0)
      setLiquidity(0)
    }
  }

  const ApproveToken = async (e) => {
    e.preventDefault()
    try {
      setload(true)
      let { value, approvereturnval } = await approveQRRtoken(config.QuantumToken)
      if (value && value.status == true && parseFloat(approvereturnval) > 0) {
        setApprove(true)
        toastAlert('success', 'Approved success')
        setTimeout(() => {
          window.location.reload(false)
        }, 1000);
      }
      else if ((value && value.status == true && parseFloat(approvereturnval) <= 0)) {
        setload(false)
        toastAlert("error", "Insufficient QRR Token balance");
      }
      else {
        setload(false)
        toastAlert("error", "Transaction Rejected");

      }
    }
    catch (err) {
    }
  }

  const harvestsubmit = async (e) => {
    e.preventDefault()
    try {
      setloadharvest(true)
      const { status, pendingRewd, message } = await harvest()
      if (status == true) {
        getstakeamount()
        getreward()
        setloadharvest(false)
        toastAlert('success', message)
      }
      else if (status == false) {
        setloadharvest(false)
        toastAlert('error', message)
      }
    }
    catch (err) {
    }
  }
  const claimrewards = async (e) => {
    e.preventDefault()
    setloadclaim(true)
    const { status, message } = await claimrewardsAction()
    if (status == true) {
      // var data = await getQRRBalance()
      setloadclaim(false)
      toastAlert('success', message)
      setTimeout(() => {
        window.location.reload(false)
      }, 1000);
    }
    else {
      setloadclaim(false)
      toastAlert('error', message)
    }
  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar

      />
      <div className="buytoken staking stakingchg">
        <div className="buytokenbg">
          <div className="container">
            <div className="head">
              <h1 className="main_title">STAKE QRR TOKEN</h1>
              <p className="stakeline"><span style={{ fontWeight: "bold" }}>Staking</span> is a way of earning </p>
              <p className="stakeline">passive income</p>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="box">
                  <div className="head_box">

                    <div className="head_box_flx">
                      <div>
                        <h5>QRR</h5>
                        <p>Earn QUANTUM REAR RESISTANCE</p>
                      </div>
                      <div>
                        {/* <h5>110%</h5>
                  <p>Est. APR</p> */}
                        <img
                          src={require("../assets/images/img2.png")}
                          className="img-fluid"
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box_ctn">
                    {/* <p className="text-center" >Total value Locked 0.00</p> */}
                    <ul>
                      <li><p>APY</p><span>{parseFloat(apypercent)}</span></li>
                      <li>
                        <div><p>Earned</p><span>{rewardAmt}</span></div>
                        <div className="btnbdr">
                          {
                            loadharvest == false ?
                              <button class="primary_btn" onClick={harvestsubmit}>HARVEST</button>
                              :
                              <button class="primary_btn" disabled>Processing....</button>
                          }
                        </div>
                      </li>
                      <li><p>Start Earning</p></li>
                      <li><p>Staked Amount</p><span>{parseFloat(stakeamt)}</span> </li>
                      {
                        approve == true ? <li className="btns">
                          <button class="primary_btn" data-bs-toggle="modal"
                            data-bs-target="#stakemodal1">-</button>
                          <button class="primary_btn" data-bs-toggle="modal"
                            data-bs-target="#stakemodal">+</button>
                        </li> : ""
                      }

                      {/* <li><p>UnStaked Amount</p><span>0</span> </li> */}
                    </ul>

                    {
                      walletConnection && walletConnection.address && walletConnection.address == '' ?
                        <div class="blw_box">
                          <div className="btnbdr"
                          //  className="primary_btn" 
                          >
                            <button class="primary_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect Wallet</button>
                          </div>
                        </div> :
                        approve == false && walletConnection && walletConnection.address && walletConnection.address !== '' ?

                          <div class="blw_box">
                            <div className="btnbdr"
                            >
                              {load == true ?
                                <button class="primary_btn" disabled>Processing...</button> :
                                <button class="primary_btn" onClick={ApproveToken}>Approve</button>
                              }
                            </div>
                          </div>
                          :
                          approve == true && walletConnection && walletConnection.address && walletConnection.address == '' ?
                            <div class="blw_box">
                              <div className="btnbdr"
                              //  className="primary_btn" 
                              >
                                <button class="primary_btn" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect Wallet</button>
                              </div>
                            </div>


                            : ""
                      //   <div class="blw_box">
                      //     <div className="btnbdr"
                      //       //  className="primary_btn" 
                      //       data-bs-toggle="modal"
                      //       data-bs-target="#stakemodal"
                      //     >
                      //       {/* {    stakeamt && parseFloat(stakeamt) <= 0 && */}
                      //       <button class="primary_btn">STAKE</button>
                      //       {/* } */}
                      //     </div>
                      //   </div>
                      // : 


                    }
                    {
                      walletConnection && walletConnection.address && walletConnection.address != '' ?

                        loadclaim == false ?
                          <button class="claim primary_btn" onClick={claimrewards}>Claim</button>
                          : <button class="claim primary_btn" disabled>Processing...</button>
                        : ""
                    }

                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Details
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body pt-0">
                          <ul className="mb-0">
                            <li><p>Liquidity</p><span>{liquidityval}</span></li>
                          </ul>
                          <div className="text-end">
                            <a href={config.txUrlAddress + config.StakingContract} target="_blank" className="view">view contract</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4">
              <div className="box">
                <div className="head_box">
                   
                  <div className="head_box_flx">
                  <div>
                  <h5>QRR-BNB LP</h5>
                  <p>Earn QUANTUM REAR RESISTANCE</p>
                  </div>
                  <div>
                  <img
                      src={require("../assets/images/coin_icon.png")}
                      className="img-fluid"
                      alt="Icon"
                    />
                  </div>
                  </div>
                </div>
                <div className="box_ctn">
                  <p className="text-center" >Total value Locked 0.00</p>
                  <ul>
                    <li><p>APY</p><span>0</span></li>
                    <li>
                     <div><p>Earned</p><span>0</span></div>
                     <div className="btnbdr">
                     <button class="primary_btn">HARVEST</button>
                     </div>
                     </li>
                     <li><p>Start Earning</p></li>
                     <li><p>Staked Amount</p><span>0</span> </li>
                     <li className="btns">
                      <button class="primary_btn">-</button>
                      <button class="primary_btn">+</button>
                     </li>
                     <li><p>UnStaked Amount</p><span>0</span> </li>
                  </ul>
                  
                  <div class="blw_box">
                  <div className="btnbdr"
                    //  className="primary_btn" 
                     data-bs-toggle="modal"
                     data-bs-target="#stakemodal"
                   ><button class="primary_btn">STAKE</button></div>
                  </div>
                </div>
                  <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingtwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="false" aria-controls="collapsetwo">
       Details
      </button>
        </h2>
        <div id="collapsetwo" class="accordion-collapse collapse" aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
      </div>
         </div>
        </div>
                 </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <StakeModal
        getstakeamount={getstakeamount}
        claimrewards={claimrewards}
        stakebalnce={stakebalnce}
      />
      <RecentTransactionnModal />
    </div>
  );
}
