import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Link } from "react-router-dom";
import isEmpty from "is-empty";
import { useSelector } from "react-redux";
import { approve, getbalance, getQRRBalance } from "../action/contractActions.js";
import {
  checkAllowance, depositBNB, depositBUSD,
  getAdminBalance, getPriceBNB, getPriceBUSD,
  getTokenfromBnb, getTokenfromBusd
}
  from "../helper/calculation.js";
import { convert } from "../helper/convert.js";
import { connection } from "../helper/connection.js";
import config from '../config/config'
import WalletModal from "./WalletModal.js";
import QuantumIcoabi from '../ABI/QuantumIcoabi'
import { toastAlert } from "../helper/toastAlert.js";
import { toFixedFormatValue } from "../helper/customMath.js";
import RecentTransactionnModal from "./RecentTransactionModal.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Buy_Token(props) {
  const [load, setLoad] = useState(false)
  const [tokenName, settokenName] = useState('BNB')
  const [fromvalue, setfromvalue] = useState();
  const [toValue, settoValue] = useState();
  const [balance_, setBalance] = useState({})
  const [Address, setAddress] = useState('')
  const [BNBprice, setBNBPrice] = useState(0)
  const [fromError, setfromError] = useState({ insuffucient: "", allowance: "" });
  const [Error, setValidError] = useState('')
  const [qrrbal, setQrrBalance] = useState(0)
  const [transactionHashh, settransactionHash] = useState('')

  const walletConnection = useSelector((state) => state.walletConnection);

  useEffect(() => {
    getUserDetail()
    getQRRBalancee()
  }, [walletConnection])

  const getUserDetail = async () => {
    var get = await connection();
    if (get && get.address) {
      setAddress(get.address)

      var web3 = get.web3;
      let Contractsss = new web3.eth.Contract(QuantumIcoabi, config.QUANTUMICO);
      let amount = (parseFloat(1) * (10 ** 18))
      amount = await convert(amount)
      amount = amount.toString()
      var checkDeci = amount.split('.')
      if (checkDeci.length === 2) {
        amount = checkDeci[0]
      }
      let perTokenss = await Contractsss.methods.getTokenfromBnb(amount.toString()).call();
      perTokenss = perTokenss / 1e10
      perTokenss = await toFixedFormatValue(parseFloat(perTokenss));
      setBNBPrice(perTokenss)
      if (tokenName == 'BNB') {
        let balance = await getbalance(get.address, tokenName);
        setBalance(balance)
      }
      else {
        let balance = await getbalance(config.BUSDAddress, tokenName);
        setBalance(balance)
      }
    }
  }
  const getQRRBalancee = async () => {
    const data = await getQRRBalance()
    if (data) {
      let bal = parseFloat(data.balance)
      setQrrBalance(bal)
    }
    else {
      setQrrBalance(0)
    }
  }


  const handleChange = async (e) => {
    let address = Address
    let value = e.target.value
    settokenName(value)
    let result = {};
    let balance = {};
    if (value == 'BUSD') {
      balance = await getbalance(config.BUSDAddress, value);
      setBalance(balance)
      result = await getTokenfromBusd(fromvalue, value)
      if (result && result.status == true) {
        setfromvalue(fromvalue)
        settoValue(result && result.perToken)
        // ValidateInsuffucientAllowance(result.perToken, config.BUSDAddress, balance, result.currency, 18);
        ValidateInsuffucientAllowance(fromvalue, config.BUSDAddress, balance, result.currency, 18);
      }
    }
    else if (value == 'BNB') {
      balance = await getbalance(address, value);
      setBalance(balance)
      result = await getTokenfromBnb(fromvalue, value)
      if (result && result.status == true) {
        setfromvalue(fromvalue)
        settoValue(result && result.perToken)
      }
    }
    window.$('#token_modal').modal('hide');
  }

  const inputChangeFunction = async (value, tokenName) => {
    // if (isNaN(value) || value <= 0) return
    if (value && parseFloat(value) >= 0) {
      // var value = parseFloat(value)
      let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
      setValidError('')
      var value_s = value.toString()
      value_s = await convert(value_s)
      var checkDeci = value_s.split('.')
      if (checkDeci.length === 2) {
        if (checkDeci[1] && checkDeci[1].length && checkDeci[1].length > 0) {
          var length = checkDeci[1].length;
          if (length > 8) {
            length = config.toFixedvalue;
          }
          value_s = parseFloat(value_s)
          var resultone = value_s.toFixed(length);
          setfromvalue(resultone)
        }
      }
      else {
        var resultone = value_s;
        setfromvalue(resultone)
      }
      let result = {};
      let balance = {};
      if (tokenName == 'BUSD') {
        balance = await getbalance(config.BUSDAddress, tokenName);
        setBalance(balance)
        result = await getTokenfromBusd(resultone, tokenName)
        if (result && result.status == true) {
          settoValue(result && result.perToken)
          if (result && parseFloat(result.perToken) > 0) {
            // ValidateInsuffucientAllowance(result.perToken, config.BUSDAddress, balance, result.currency, 18);
            ValidateInsuffucientAllowance(resultone, config.BUSDAddress, balance, result.currency, 18);
          }
        }
      }
      else if (tokenName == 'BNB') {
        balance = await getbalance(address, tokenName);
        setBalance(balance)
        result = await getTokenfromBnb(resultone, tokenName)
        if (result && result.status == true) {
          settoValue(result && result.perToken)
        }
      }
    }
    else {
      setfromvalue('')
      settoValue('')
    }

  }
  const setMaxVal = async (value, tokenName) => {
    setfromvalue(value)
    inputChangeFunction(value, tokenName)
  }

  const inputChange = async (e) => {
    const { id, value } = e.target;
    await inputChangeFunction(value, tokenName)
  }

  const outputchange = async (e) => {
    const { id, value } = e.target;
    // if (isNaN(value) || value <= 0) return
    if (value && parseFloat(value) >= 0) {
      setValidError('')
      var value_s = value.toString()
      value_s = await convert(value_s)
      var checkDeci = value_s.split('.')
      if (checkDeci.length === 2) {
        if (checkDeci[1] && checkDeci[1].length && checkDeci[1].length > 0) {
          var length = checkDeci[1].length;
          if (length > 8) {
            length = config.toFixedvalue;
          }
          value_s = parseFloat(value_s)
          var resulttwo = value_s.toFixed(length);
          resulttwo = parseFloat(resulttwo)
          settoValue(resulttwo)
        }
      }
      else {
        var resulttwo = parseFloat(value_s);
        settoValue(resulttwo)
      }
      let result = {};
      if (tokenName == 'BUSD') {
        result = await getPriceBUSD(resulttwo)
        setfromvalue(result && result.inputCal)
      }
      else if (tokenName == 'BNB') {
        result = await getPriceBNB(resulttwo)
        setfromvalue(result && result.inputCal)
      }
    }
    else {
      setfromvalue('')
      settoValue('')
    }
  }

  async function ValidateInsuffucientAllowance(amount, address, balance, symbol, decimals) {
    var { fromallowance, frominsuffucient } = await checkAllowance(amount, address, balance, symbol, decimals);
    if (amount > 0) {
      setfromError({ ...fromError, ...{ "insuffucient": frominsuffucient, "allowance": fromallowance } });
    }
  }

  const BuyTokens = async (e) => {
    e.preventDefault()

    if (((fromvalue) <= 0 || isEmpty((fromvalue))) || ((toValue) <= 0 || isEmpty((toValue)))) {
      setLoad(false)
      let error = 'Please enter valid amount'
      return setValidError(error)
    }

    else {

      const getadminBal = await getAdminBalance()

      if (parseFloat(getadminBal) < parseFloat(toValue)) {
        setLoad(false)
        return toastAlert('error', 'Insufficient Balance(QRR)')
      }
      else {
        let address = walletConnection && walletConnection.address !== "" ? walletConnection.address : ''
        let result = {}
        if (tokenName == 'BUSD') {
          if (fromError.insuffucient == 'yes' || parseFloat(balance_.balance) < parseFloat(fromvalue)) {
            return toastAlert('error', 'Insufficient BUSD Balance')
          }
          if (fromError.allowance === "yes") {
            setLoad(true)
            var { status } = await approve(config.BUSDAddress)
            if (status == true) {
              setLoad(true)
              result = await depositBUSD(tokenName, fromvalue)
            }
          }
          else {
            setLoad(true)
            result = await depositBUSD(tokenName, fromvalue)
          }
        }
        else if (tokenName == 'BNB') {
          let bnbbalance = await getbalance(address, tokenName);
          let getbnbbal = bnbbalance && bnbbalance.balance
          // console.log(getbnbbal,fromvalue,'fromvaluefromvaluefromvalue')
          if ((parseFloat(getbnbbal) < parseFloat(fromvalue))) {
            setLoad(false)
             return toastAlert('error', `Insufficient ${config.BNBSYMBOL} balance`)
          }
          // else if ((parseFloat(getbnbbal).toFixed(8) == parseFloat(fromvalue).toFixed(8))) {
          //   setLoad(false)
          //   return toastAlert('error', `Insufficient Balance for Gas Fee`)
          // }
          else {
            setLoad(true)
            result = await depositBNB(tokenName, fromvalue, toValue)
          }
        }
        if (!isEmpty(result)) {
          settransactionHash(result.transactionHash)
          getUserDetail()
          getQRRBalancee()
          toastAlert('success', 'Success')
          window.$('#recent_trans_modal').modal('show');
          setLoad(false)
          setfromvalue(0)
          settoValue(0)
          // let data = {
          //   address: address,
          //   currency: tokenName,
          //   depositAmt: parseFloat(fromvalue),
          //   earnedAmt: parseFloat(toValue),
          //   transactionHash: result.transactionHash
          // }
          // let { status } = await saveDetails(data)
          // if (status == true) {
          //   toastAlert('success', 'Success')
          //   setLoad(false)
          //   setfromvalue(0)
          //   settoValue(0)
          // }
        }
        else {
          setLoad(false)
          settransactionHash('')
          toastAlert('error', 'Transaction Rejected')

        }
      }
    }
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar
      />



      <div className="buytoken">
        <div className="buytokenbg">
          <div className="container">
            <div className="head">
              <h2 className="main_title">Buy  QUANTUM REAR RESISTANCE Presale</h2>
              <p>
                Participate in  QUANTUM REAR RESISTANCE staking, earn  QUANTUM REAR RESISTANCE while you
                sleep
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="box">
                  <div className="head_box">
                    <h5>Current Price</h5>
                    <p>
                      <span>1</span> BNB = <span>{BNBprice}</span>  QUANTUM REAR RESISTANCE
                    </p>
                  </div>
                  <div className="box_ctn">
                    {/* <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Dropdown button
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#">BNB</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>
  </ul>
                </div> */}
                    <div className="label">
                      <p></p>
                      <p>Balance:<span>{`${' ' + config.tokenSymbol}` + ' ' + `${parseFloat(qrrbal) ? parseFloat(qrrbal).toFixed(4) : 0}`}</span></p>

                    </div>
                    <select class="form-control" onChange={handleChange}>
                      <option>BNB</option>
                      <option>BUSD</option>
                    </select>
                    <div className="label">
                      <p></p>
                      {/* <p>Min-Max: <span>0.1 - 10 BNB</span></p> */}
                      <p>Balance:<span>{`${' ' + tokenName}` + ' ' + `${parseFloat(balance_.balance) ? parseFloat(balance_.balance).toFixed(4) : 0}`}</span></p>
                    </div>
                    {/* <div className="number_wrapper"> */}
                    <div className={isEmpty(Error) ? "number_wrapper" : "number_wrappertwo"}>
                      <div class="input-group mb-3">
                        <input
                          type="number"
                          class="form-control"
                          onChange={inputChange}
                          value={fromvalue}
                          placeholder="Enter your amount"
                        />
                        {/* <i class="fas fa-sort"></i> */}
                        <div class="input-group-text" onClick={() => {
                          setMaxVal(balance_.balance, tokenName);
                        }}>

                          Max
                        </div>
                      </div>
                      {!isEmpty(Error) ? <span className='errortext'>{Error}</span> : ''}
                    </div>
                    <p className="receive" >You will get: QRR
                      {/* <span> 0 </span> */}
                      <input
                        type="number"
                        class="form-control"
                        onChange={outputchange}
                        value={toValue}
                        placeholder="Enter your amount"
                      />
                    </p>
                    {!isEmpty(Error) ? <span className='errortext'>{Error}</span> : ''}
                    <div class="blw_box">
                      <div className="btnbdr">
                        {/* <button class="primary_btn">CONNECT WALLET</button> */}
                        {
                          (load == false) && walletConnection && walletConnection.address && walletConnection.address !== '' ?
                            <button type="button" className='primary_btn' onClick={BuyTokens}>Submit</button>
                            : (walletConnection.address == '') ? <button className="primary_btn"
                              data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect wallet</button>
                              : (load == true) ? <button type="button" className='primary_btn' disabled>Processing...please wait</button>
                                : ""
                        }

                      </div>
                      <p>You will receive <span>{toValue ? toValue : 0} QUANTUM REAR RESISTANCE</span></p>
                      <p>Looking for support?
                        <span>
                          {/* <Link to="/">Contact</Link> */}
                          <a href={config.txUrlAddress + config.QUANTUMICO} target="_blank" className="view">Contract</a>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <WalletModal /> */}
      <RecentTransactionnModal
        transactionHash={transactionHashh}
      />
      <Footer />
    </div>
  );
}
